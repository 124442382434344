<template>
  <div id="wizard">
    <!-- <div>{{ now | moment("jD jMMMM,jYYYY HH:mm") }}</div>
    <button type="button" @click="createTestCalender(17)">fgfg</button> -->
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="قبلی"
      next-button-text="بعدی"
      class="steps-transparent mb-3"
      @on-complete="formSubmitted"
      ref="formWizard"
    >
      <!-- account detail tab -->
      <tab-content
        :before-change="beforeTab1Switch"
        title="اطلاعات اولیه"
        icon="feather icon-info"
        :lazy="true"
      >
        <b-row key="879">
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">اطلاعات اولیه</h5>
            <small class="text-muted">
              اطلاعات اولیه اقامتگاه را وارد کنید.
            </small>
          </b-col>
          <b-col key="key-name" md="3">
            <b-form-group>
              <label for="name1" class="text-capitalize"
                >عنوان:<span class="text-danger">*</span></label
              >
              <b-form-input
                lazy
                key="name1"
                v-model="residenceInput.name"
                id="name"
                placeholder="شیشلی مرکز "
              />
            </b-form-group>
          </b-col>
          <b-col key="key-slug" md="3">
            <b-form-group>
              <label for="slug" class="text-capitalize"
                >slug|اسلاگ:<span class="text-danger">*</span></label
              >
              <b-form-input
                lazy
                v-model="residenceInput.slug"
                id="slug"
                key="slug1"
                placeholder="same1"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label for="host" class="text-capitalize">
                میزبان :<span class="text-danger">*</span></label
              >
              <v-select
                id="host"
                :components="{ OpenIndicator }"
                class="bg-white"
                :clearable="false"
                v-model="residenceInput.host_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="owners"
                label="full_name"
                :reduce="(owner) => owner.id"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="host" class="text-capitalize">
                انتخاب مدیر/سرپرست ساختمان :<span class="text-danger">*</span></label
              >
              <v-select
                  id="host"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="residenceInput.clerk_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :get-option-label="(option) => option.first_name+option.last_name"
                  :options="clerks"
                  :reduce="(clerk) => clerk.id"
              >
              <template #option="{ first_name,last_name,id }" style="direction: ltr">
                <cite>{{first_name}} {{last_name}}</cite>

              </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="host" class="text-capitalize">
                انتخاب واحد ساختمان :<span class="text-danger">*</span></label
              >
              <v-select
                  id="host"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="residenceInput.unit_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :get-option-label="(option) => option.name"
                  :options="units"
                  :reduce="(unit) => unit.id"
              >
                <template #option="{ name }" style="direction: ltr">
                  <cite>{{name}}</cite>

                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="name1" class="text-capitalize"
              >نحوه ترتیب نمایش در سایت (اعداد کمتر اولویت بالاتر) :<span class="text-danger">*</span></label
              >
              <b-form-input
                  lazy
                  key="name1"
                  v-model="residenceInput.show_order_number"
                  id="name"
                  placeholder="نحوه ترتیب نمایش در سایت"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
              <label for="province_status" class="text-capitalize">
                وضعیت :<span class="text-danger">*</span>
              </label>
              <b-form-checkbox
                v-model="residenceInput.status"
                :aria-describedby="ariaDescribedby"
                value="active"
                >فعال</b-form-checkbox
              >
              <!-- @input="removeErrors('status')" -->
              <b-form-checkbox
                v-model="residenceInput.status"
                :aria-describedby="ariaDescribedby"
                value="inactive"
                >پیش نویس</b-form-checkbox
              >
              <!-- @input="removeErrors('status')"
          <ErrorMessage
            errorType="status"
            :errorMessages="errorMessage"
          ></ErrorMessage> -->
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
              <label for="province_close_booking" class="text-capitalize">
                بستن تقویم رزرو :<span class="text-danger">*</span>
              </label>
              <b-form-checkbox
                  v-model="residenceInput.close_booking"
                  :aria-describedby="ariaDescribedby"
                  value="active"
              >فعال</b-form-checkbox
              >
              <!-- @input="removeErrors('close_booking')" -->
              <b-form-checkbox
                  v-model="residenceInput.close_booking"
                  :aria-describedby="ariaDescribedby"
                  value="inactive"
              >پیش نویس</b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
              <label for="province_show_index_page" class="text-capitalize">
                وضعیت نمایش :<span class="text-danger">*</span>
              </label>
              <div >
                <span style="color: #00b782" v-if="residenceInput.show_index_page">
                      فعال
                </span>
                <span v-else>
                  غیر فعال
                </span>
              </div>
              <b-form-checkbox
                  v-model="residenceInput.show_index_page"
                  :aria-describedby="ariaDescribedby"
                  :value="1"
              >نمایش در صفحه اصلی</b-form-checkbox
              >
              <!-- @input="removeErrors('status')" -->
              <b-form-checkbox
                  v-model="residenceInput.show_index_page"
                  :aria-describedby="ariaDescribedby"
                  :value="0"
              >عدم نمایش در صفحه اصلی</b-form-checkbox
              >
              <!-- @input="removeErrors('status')"
          <ErrorMessage
            errorType="status"
            :errorMessages="errorMessage"
          ></ErrorMessage> -->
            </b-form-group>
          </b-col>


          <b-col md="3">
            <b-form-group>
              <label for="host" class="text-capitalize">
                دسته بندی :<span class="text-danger">*</span>
              </label>
              <v-select
                id="host"
                :components="{ OpenIndicator }"
                class="bg-white"
                :clearable="false"
                v-model="residenceInput.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                label="name"
                :reduce="(category) => category.id"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label for="host" class="text-capitalize">
                ویژگی های اقامتگاه  :<span class="text-danger">*</span>
              </label>
              <v-select
                id="host"
                :components="{ OpenIndicator }"
                class="bg-white"
                :clearable="false"
                multiple
                v-model="residenceInput.properties"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="properties"
                label="name"
                :reduce="(property) => property.id"
              />
            </b-form-group>
          </b-col>


          <b-col md="3">
            <b-form-group>
              <label for="similar_unit" class="text-capitalize">
                تعداد واحد مشابه :<span class="text-danger">*</span>
              </label>
              <b-form-input
                lazy
                v-model="residenceInput.similar_unit"
                id="similar_unit"
                type="number"
                placeholder="مثال: 4"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="size_foundation" class="text-capitalize">
                متراژ زیربنا (متر):<span class="text-danger">*</span>
              </label>
              <b-form-input
                lazy
                v-model="residenceInput.size_foundation"
                id="size_foundation"
                type="number"
                placeholder="مثال : 80"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="size_land" class="text-capitalize">
                متراژ زمین و محوطه اطراف (متر):<span class="text-danger"
                  >*</span
                >
              </label>
              <b-form-input
                lazy
                id="size_land"
                type="number"
                placeholder="شامل بخش هایی مانند حیاط و باغ"
                v-model="residenceInput.size_land"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="floor" class="text-capitalize">
                طبقه:<span class="text-danger">*</span>
              </label>
              <b-form-input
                lazy
                v-model="residenceInput.floor"
                id="floor"
                type="number"
                placeholder="مثال: 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="bathroom">
              <label for="bathroom" class="text-capitalize">
                تعداد حمام:<span class="text-danger">*</span>
              </label>
              <b-form-input
                lazy
                v-model="residenceInput.bathroom"
                id="bathroom"
                type="number"
                placeholder="مثال: 2"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group dir="rtl" label-for="delivery_time">
              <label for="delivery_time" class="text-capitalize">
                زمان تحویل از:<span class="text-danger">*</span>
              </label>
              <v-select
                  id="residence_area"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="residenceInput.delivery_time"
                  dir="rtl"
                  :options="residence_time"
              />
<!--              <b-form-timepicker
                dropright
                dropup
                dir="rtl"
                id="delivery_time"
                v-model.lazy="residenceInput.delivery_time"
                v-bind="labels[locale] || {}"
                :locale="locale"
                @context="onContext"
                label-pm="ظهر"
                label-am="صبح"
              ></b-form-timepicker>-->
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group dir="rtl" label-for="delivery_time_to">
              <label for="delivery_time_to" class="text-capitalize">
                زمان تحویل تا:<span class="text-danger">*</span>
              </label>
              <v-select
                  id="residence_area"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="residenceInput.delivery_time_to"
                  dir="rtl"
                  :options="residence_time"
              />
              <!--              <b-form-timepicker
                              dropright
                              dropup
                              dir="rtl"
                              id="delivery_time"
                              v-model.lazy="residenceInput.delivery_time"
                              v-bind="labels[locale] || {}"
                              :locale="locale"
                              @context="onContext"
                              label-pm="ظهر"
                              label-am="صبح"
                            ></b-form-timepicker>-->
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group dir="rtl">
              <label for="discharge_time" class="text-capitalize">
                زمان تخلیه از:<span class="text-danger">*</span>
              </label>
              <v-select
                  id="residence_area"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="residenceInput.discharge_time"
                  dir="rtl"
                  :options="residence_time"
              />
<!--              <b-form-timepicker
                dropright
                dropup
                dir="rtl"
                label-pm="ظهر"
                label-am="صبح"
                id="discharge_time"
                v-model="residenceInput.discharge_time"
                v-bind="labels[locale] || {}"
                :locale="locale"
                @context="onContext"
              ></b-form-timepicker>-->
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group dir="rtl">
              <label for="discharge_time_to" class="text-capitalize">
                زمان تخلیه تا:<span class="text-danger">*</span>
              </label>
              {{residenceInput.discharge_time_to}}
              <v-select
                  id="discharge_time_to"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="residenceInput.discharge_time_to"
                  dir="rtl"
                  :options="residence_time"
              />
              <!--              <b-form-timepicker
                              dropright
                              dropup
                              dir="rtl"
                              label-pm="ظهر"
                              label-am="صبح"
                              id="discharge_time"
                              v-model="residenceInput.discharge_time"
                              v-bind="labels[locale] || {}"
                              :locale="locale"
                              @context="onContext"
                            ></b-form-timepicker>-->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="residence_area" class="text-capitalize">
                منطقه اقامتگاه:<span class="text-danger">*</span>
              </label>
              <v-select
                id="residence_area"
                :components="{ OpenIndicator }"
                class="bg-white"
                :clearable="false"
                v-model="residenceInput.residence_area"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="residence_area"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label for="rental_method" class="text-capitalize">
                روش اجاره:<span class="text-danger">*</span>
              </label>
              <v-select
                id="rental_method"
                :components="{ OpenIndicator }"
                class="bg-white"
                :clearable="false"
                v-model.lazy="residenceInput.rental_method"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rental_method"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <b-form-group>
              <label for="commission_price" class="text-capitalize">
                امتیاز  ( مثلا 4.1) :
              </label>
              <b-form-input
                  lazy
                  v-model="residenceInput.score"
                  id="commission_price"
                  type="number"
                  placeholder="مثال: 4.1"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <b-form-group>
              <label for="commission_price" class="text-capitalize">
                کمیسیون دریافتی از میزبان (بصورت درصد مثلا 10) :
              </label>
              <b-form-input
                  lazy
                  v-model="residenceInput.commission_price"
                  id="commission_price"
                  type="number"
                  placeholder="مثال: 10"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="description" class="text-capitalize"
                >توضیحات:<span class="text-danger">*</span></label
              >
              <quill-editor
                  style=" direction: rtl !important;text-align: right !important;height: 200px"
                  v-model="residenceInput.description"
              />
<!--              <vue-editor
                v-model="residenceInput.description"
                :editorToolbar="customToolbar"
              ></vue-editor>-->
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="covid" class="text-capitalize"
                >توضیحات درباره کرونا:</label
              >
              <b-form-textarea
                lazy
                v-model="residenceInput.covid"
                rows="2"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="blog_url" class="text-capitalize">
                آدرس لینک وبلاگ (url):</label
              >
              <b-form-input
                lazy
                v-model="residenceInput.blog_url"
                placeholder="لینک مقاله مربوطه را وارد کنید."
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- personal details -->
      <tab-content
        :before-change="beforeTab2Switch"
        title="موقعیت"
        icon="feather icon-user"
        :lazy="true"
      >
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">موقعیت اقامتگاه</h5>
            <small class="text-muted">آدرس و موقعیت روی نقشه</small>
          </b-col>
          <b-col md="6">
            <b-form-group class="">
              <label for="city_province" class="text-capitalize">
                استان :</label
              >
              <v-select
                id="city_province"
                :components="{ OpenIndicator }"
                class="bg-white"
                :clearable="false"
                v-model="residenceInput.state_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :reduce="(province) => province.id"
                :options="provinces"
                placeholder="برای جستجو تایپ کنید"
                @input="getSelectedProvincesCity"
              />
              <!-- <ErrorMessage
              errorType="state"
              :errorMessages="errorMessage"
            ></ErrorMessage> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="">
              <label for="city_province" class="text-capitalize"> شهر :</label>
              <v-select
                :loading="showGettingCityLoading"
                id="city_province"
                :components="{ OpenIndicator }"
                class="bg-white"
                :clearable="false"
                v-model="residenceInput.city_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :reduce="(city) => city.id"
                :options="cities"
                @input="getSelectedCityLocaion"
                placeholder="برای جستجو تایپ کنید"
              />
              <!-- <ErrorMessage
              errorType="city"
              :errorMessages="errorMessage"
            ></ErrorMessage> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="منطقه / شهرک اقامتگاه" label-for="aria">
              <b-form-input
                  lazy
                  id="aria"
                  placeholder="کوچه یکم، پلاک دو"
                  rows="5"
                  v-model="residenceInput.aria"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="لوکیشن گوگل یا بله یا نشان" label-for="location">
              <b-form-textarea
                  lazy
                  id="location"
                  placeholder="لوکیشن گوگل یا بله یا نشان"
                  rows="5"
                  v-model="residenceInput.location"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="آدرس دقیق" label-for="address">
              <b-form-textarea
                lazy
                id="address"
                placeholder="کوچه یکم، پلاک دو"
                rows="5"
                v-model="residenceInput.address"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <div id="vvv" style="position: relative">
              <l-map
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 400px"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
                ref="mymap"
                @ready="doSomethingOnReady()"
                
              >
                <l-tile-layer :url="url" :attribution="attribution" />
              </l-map>

              <feather-icon
                icon="MapPinIcon"
                size="20"
                fill="#FFF "
                style="
                  position: absolute;
                  top: 50%;
                  margin-top: -20px;
                  z-index: 9999;
                  left: 50%;
                  margin-left: -10px;
                "
              />
            </div>
          </b-col>
        </b-row>
      </tab-content>
      <!-- personal details -->
      <tab-content
        :before-change="beforeTab3Switch"
        title="قوانین و مقررات"
        icon="feather icon-user"
        :lazy="true"
      >
        <b-row>
          <b-col cols="12" class="mb-1">
            <h5 class="mb-0">قوانین لغو رزرو</h5>
            <small class="text-muted"
              >از بین گزینه های زیر یک مورد را انتخاب کنید
            </small>
          </b-col>
          <b-col md="6" class="mb-2">
            <div v-if="residenceInput.rules.length > 0">
              سیاست این اقامتگاه

              {{
                residenceInput.rules[0].residence_rule
                  ? residenceInput.rules[0].residence_rule.name
                  : residenceInput.rules[0].name
              }}
              می باشد.
            </div>
            <b-form-group v-slot="{ ariaDescribedby }" class="" v-if="editMode">
<!--              <b-form-radio
                  v-model="Selected"
                  name="some-radios"
                  value="A"
              >
                Checked
              </b-form-radio>-->


              <b-form-radio
                :aria-describedby="ariaDescribedby"
                v-model="residenceInput.rules[0].residence_rule.name"
                v-for="rule in rules"
                :key="rule.id"
                :value="rule.name"
                >
                {{ rule.name }}
                <small class="text-muted">{{ rule.description }}</small>
              </b-form-radio>

              <!-- <ErrorMessage
              errorType="state"
              :errorMessages="errorMessage"
            ></ErrorMessage> -->
            </b-form-group>

            <b-form-group v-slot="{ ariaDescribedby }" class="" v-else>
              <!--              <b-form-radio
                                v-model="Selected"
                                name="some-radios"
                                value="A"
                            >
                              Checked
                            </b-form-radio>-->


              <b-form-radio
                  :aria-describedby="ariaDescribedby"
                  v-model="residenceInput.rules[0]"
                  v-for="rule in rules"
                  :key="rule.id"
                  :value="rule"
              >
                {{ rule.name }}
                <small class="text-muted">{{ rule.description }}</small>
              </b-form-radio>

              <!-- <ErrorMessage
              errorType="state"
              :errorMessages="errorMessage"
            ></ErrorMessage> -->
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-1">
            <h5 class="mb-0">مقررات اقامتگاه</h5>
            <small class="text-muted">مقررات اقامتگاه را انتخاب کنید </small>
          </b-col>
          <b-col
            md="3"
            v-for="(compo, index0) in regulationsItemArray"
            :key="index0"
          >
            <div>
              <b-form-checkbox v-model="compo.disable" :label="compo.name">
                {{
                  compo.residence_regulation
                    ? compo.residence_regulation.name
                    : compo.name
                }}
              </b-form-checkbox>
            </div>
            <div>
              <b-form-input
                lazy
                :disabled="!compo.disable"
                type="text"
                v-model="compo.ownerDescription"
                size="sm"
              />
            </div>
          </b-col>
        </b-row>
      </tab-content>
      <!-- address -->
      <tab-content
        title="اتاق/ امکانات"
        icon="feather icon-map-pin"
        :lazy="true"
      >
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">اتاق / امکانات</h5>
            <small class="text-muted"
              >اتاق ها و امکانات اقامتگاه را مشخص کنید</small
            >
          </b-col>
          <b-col md="3">
            <b-form-group label="تعداد اتاق خواب" label-for="bedrooms">
              <!-- @change="value2 = $events" -->
              <b-form-spinbutton
                id="sb-input"
                v-model="bedrooms"
                size="sm"
                min="0"
                max="10"
              ></b-form-spinbutton>
            </b-form-group>
          </b-col>
          <b-col md="9">
            <!-- @change="value2 = $events" -->
            <b-form-group>
              <b-badge v-if="bedrooms == 0">فاقد اتاق خواب</b-badge>
            </b-form-group>
          </b-col>
          <template v-for="(value, key, index) in residenceInput.rooms">
            <b-col
              md="12"
              v-if="
                key !== '0' && key !== 'sumOfRooms' && key !== 'sumOfAllBed'
              "
            >
              <b-badge>اتاق{{ key }}</b-badge></b-col
            >
            <b-col v-if="key !== 'sumOfRooms' && key !== 'sumOfAllBed'" md="3">
              <b-form-group label="تخت سینگل (یک نفره)" label-for="singleBed">
                <b-form-spinbutton
                  size="sm"
                  id="singleBed"
                  v-model="value.single"
                  wrap
                  min="0"
                ></b-form-spinbutton>
              </b-form-group>
            </b-col>
            <b-col v-if="key !== 'sumOfRooms' && key !== 'sumOfAllBed'" md="3">
              <b-form-group label="تخت دبل (دو نفره)" label-for="doubleBed">
                <b-form-spinbutton
                  size="sm"
                  id="doubleBed"
                  v-model="value.double"
                  wrap
                  min="0"
                ></b-form-spinbutton>
              </b-form-group>
            </b-col>
            <b-col v-if="key !== 'sumOfRooms' && key !== 'sumOfAllBed'" md="3">
              <b-form-group label="خواب سنتی (کفخواب)" label-for="mattress">
                <b-form-spinbutton
                  size="sm"
                  id="mattress"
                  v-model="value.kaf_khab"
                  wrap
                  min="0"
                ></b-form-spinbutton>
              </b-form-group>
            </b-col>
            <b-col v-if="key !== 'sumOfRooms' && key !== 'sumOfAllBed'" md="3">
              <b-form-group label="سایر توضیحات" label-for="bedDescribtion">
                <b-form-input
                  lazy
                  size="sm"
                  v-model="value.description"
                  id="bedDescribtion"
                  placeholder="سایر توضیحات"
                />
              </b-form-group>
            </b-col>
          </template>

          <b-col class="mt-2" md="12"><h6>امکانات</h6></b-col>

          <b-col
            md="3"
            v-for="(compo, index0) in packagesItemArray"
            :key="index0"
          >
            <div>
              <b-form-checkbox v-model="compo.disable" :label="compo.name">
                {{
                  compo.residence_package
                    ? compo.residence_package.name
                    : compo.name
                }}
              </b-form-checkbox>
            </div>
            <div>
              <b-form-input
                lazy
                :disabled="!compo.disable"
                type="text"
                v-model="compo.ownerDescription"
                size="sm"
              />
            </div>
          </b-col>

          <b-col class="mt-2" md="12"><h6>ایمنی اقامتگاه</h6></b-col>
          <template v-if="autoimmunePackagesItemArray.length > 0">
            <b-col
              md="3"
              v-for="(compo, index2) in autoimmunePackagesItemArray"
              :key="index2 + 100"
            >
              <div>
                <b-form-checkbox v-model="compo.disable" :label="compo.name">
                  {{ compo.name }}
                </b-form-checkbox>
              </div>
              <div>
                <b-form-input
                  lazy
                  :disabled="!compo.disable"
                  type="text"
                  v-model="compo.ownerDescription"
                  size="sm"
                />
              </div>
            </b-col>
          </template>
          <b-col class="mt-2" md="12"><h6>اقلام بهداشتی</h6></b-col>
          <template v-if="health_itemsItemArray.length > 0">
            <b-col
              md="3"
              v-for="(compo, index3) in health_itemsItemArray"
              :key="index3 + 200"
            >
              <div>
                <b-form-checkbox v-model="compo.disable" :label="compo.name">
                  {{ compo.name }}
                </b-form-checkbox>
              </div>
              <div>
                <b-form-input
                  lazy
                  :disabled="!compo.disable"
                  type="text"
                  v-model="compo.ownerDescription"
                  size="sm"
                />
              </div>
            </b-col>
          </template>
        </b-row>
      </tab-content>

      <!-- social link -->
      <tab-content
        :before-change="beforeTab5Switch"
        title="قیمت گذاری (تومان)"
        icon="feather icon-link"
        :lazy="true"
      >
        <b-row>
          <b-col cols="6" class="mb-2">
            <h5 class="mb-0">قیمت گذاری</h5>
            <small class="text-muted"
              >نحوه قیمت گذاری و قیمت ها را وارد کنید.</small
            >

            <b-row>
              <b-col cols="12" class="mb-2">
                <b-form-group>
                  <b-form-radio
                    v-model="residenceInput.prices.priceType"
                    value="pricePerNight"
                    >قیمت به ازای هر شب</b-form-radio
                  >
                  <b-form-radio
                    v-model="residenceInput.prices.priceType"
                    value="pricePerPerson"
                    >قیمت به ازای هر فرد</b-form-radio
                  >
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mb-2">
                <b-form-group label="ایام عادی">
                  <b-form-input
                    lazy
                    :formatter="formatnumber"
                    v-model="residenceInput.prices.normal_dayes"
                    placeholder="مثال: 550000"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mb-2">
                <b-form-group label="ایام آخر هفته">
                  <b-form-input
                    lazy
                    :formatter="formatnumber"
                    v-model="residenceInput.prices.weekend_days"
                    placeholder="مثال: 600000"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mb-2">
                <b-form-group label="ایام پیک">
                  <b-form-input
                    lazy
                    :formatter="formatnumber"
                    v-model="residenceInput.prices.peack_days"
                    placeholder="مثال: 700000"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mb-2">
                <b-form-group
                  v-if="residenceInput.prices.priceType !== 'pricePerPerson'"
                  label="نفرات اضافه"
                >
                  <b-form-input
                    lazy
                    :formatter="formatnumber"
                    v-model="residenceInput.prices.extra_guest"
                    placeholder="مثال: 50000"
                    size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6" class="mb-2">
                <b-form-group>
                  <b-form-checkbox
                    :label="residenceInput.prices.pricePerBaby.name"
                    v-model="residenceInput.prices.pricePerBaby.hasPrice"
                  >
                    هزینه به ازای هر نوزاد
                  </b-form-checkbox>
                  <b-form-input
                    lazy
                    size="sm"
                    :formatter="formatnumber"
                    :disabled="!residenceInput.prices.pricePerBaby.hasPrice"
                    v-model="residenceInput.prices.pricePerBaby.price"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mb-2">
                <b-form-group>
                  <b-form-checkbox
                    :label="residenceInput.prices.pricePerAnimal.name"
                    v-model="residenceInput.prices.pricePerAnimal.hasPrice"
                  >
                    هزینه حیوانات</b-form-checkbox
                  >
                  <b-form-input
                    lazy
                    size="sm"
                    :formatter="formatnumber"
                    :disabled="!residenceInput.prices.pricePerAnimal.hasPrice"
                    v-model="residenceInput.prices.pricePerAnimal.price"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6">
            <h5 class="mb-0">ظرفیت</h5>
            <!-- <small class="text-muted"
              >   و  ها را وارد کنید.</small
            > -->
            <b-row>
              <b-col cols="12" class="mb-2">
                <b-form-group
                  label="حداقل مدت اقامت"
                  label-for="min-night-input"
                >
                  <b-form-spinbutton
                    id="min-night-input"
                    v-model="residenceInput.prices.minNight"
                    wrap
                    min="0"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="ظرفیت استاندارد"
                  label-for="standard-capacity"
                >
                  <b-form-spinbutton
                    id="standard-capacity"
                    v-model="residenceInput.prices.standardCapacity"
                    wrap
                    min="0"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="ظرفیت اضافه" label-for="max-capacity">
                  <b-form-spinbutton
                    id="max-capacity"
                    v-model="residenceInput.prices.maxCapacity"
                    wrap
                    min="0"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <b-form-checkbox
                    v-model="residenceInput.prices.extraBed"
                    :checked="residenceInput.prices.extraBed"
                    >ارائه پتو و تشک (کفخواب) به نفرات اضافه</b-form-checkbox
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="d-flex justify-content-between" md="12">
            <h5 class="mb-0 w-auto">خدمات ویژه</h5>
            <b-form-spinbutton
              v-model="specialServices"
              inline
              min="0"
              max="20"
              size="sm"
            ></b-form-spinbutton>
          </b-col>
          <template>
            <b-row
              v-for="(value, key, index) in residenceInput.special_services"
              :key="key"
            >
              <b-col v-if="index > 0" cols="3" class="mb-2">
                <b-form-group label="عنوان" label-for="min-night-input">
                  <b-form-input
                    lazy
                    size="sm"
                    id="min-night-input"
                    v-model="value.name"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="index > 0" cols="3" class="mb-2">
                <b-form-group label="هزینه" label-for="min-night-input">
                  <b-form-input
                    lazy
                    size="sm"
                    :formatter="formatnumber"
                    id="min-night-input"
                    v-model="value.price"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="index > 0" cols="3" class="mb-2">
                <b-form-group label="شیوه ارائه" label-for="min-night-input">
                  <b-form-select
                    v-model="value.method"
                    :options="methodss"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="index > 0" cols="3" class="mb-2">
                <b-form-checkbox
                  :label="value.name"
                  v-model="value.perPerson"
                  :id="`${value.name}_${index}`"
                  >به ازای هر نفر</b-form-checkbox
                >
              </b-col>
            </b-row>
          </template>
        </b-row>
      </tab-content>

      <tab-content title="مدیا" icon="feather icon-link" :lazy="true">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">آپلود عکس ها و فیلم</h5>
            <small class="text-muted">
              عکس ها و ویدئوی اقامتگاه را آپلود کنید</small
            >
          </b-col>
          <b-col
            style="position: relative"
            md="4"
            v-for="image in residenceInput.images"
            :key="image.id"
            class="p-2"
          >
            <div    v-if="image.path.original">

              <img
                  v-if="image.path.original.includes('uploads')"
                  width="100%"
                  height="380"
                  :src="$http.defaults.fileUrl+'/' + image.path.original"
              />
              <img
                  v-else
                  width="100%"
                  height="380"
                  :src="$http.defaults.fileUrl+'/uploads/' + image.path.original"
              />
              <b-button
                  type="danger"
                  icon
                  size="sm"
                  style="position: absolute; left: 0; top: 0"
                  @click="deleteImage(image, residenceInput.id)"
              >
                <BIconTrash></BIconTrash>
              </b-button>
            </div>

            <div   v-else>
              <img

                  width="100%"
                  height="380"
                  :src="$http.defaults.fileUrl + '/uploads/' + image.path"
              />
              <b-button
                  type="danger"
                  icon
                  size="sm"
                  style="position: absolute; left: 0; top: 0"
                  @click="deleteImage(image, residenceInput.id)"
              >
                <BIconTrash></BIconTrash>
              </b-button>
            </div>
          </b-col>
          <b-col md="6" lg="4" sm="12">
            <vue-upload-multiple-image
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @mark-is-primary="markIsPrimary"
              @edit-image="editImage"
              :data-images="images"
              idUpload="myIdUpload"
              :maxImage="60"
              browseText="انتخاب فایل"
              markIsPrimaryText="انتخاب به عنوان عکس اصلی"
              primaryText="عکس پیش فرض"
              dragText="عکس ها را اینجا رها کنید"
              popupText="این عکس به عنوان عکس اصلی انتخاب شده است"
              ref="imageUploader"
            ></vue-upload-multiple-image>
          </b-col>
          <b-col cols="6" class="mb-2">
            <b-form-group label="لینک پادکست اقامتگاه" label-for="min-night-input">
              <b-form-input
                lazy
                size="sm"
                id="min-night-input"
                v-model="residenceInput.aparat_embed"
              />
            </b-form-group>
            <b-form-group label="لینک  فیلم" label-for="min-night-input">
              <b-form-input
                lazy
                size="sm"
                id="min-night-input"
                v-model="residenceInput.youtube_embed"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content
        title="تنظیمات سئو (SEO)"
        icon="feather icon-link"
        :lazy="true"
      >
        <b-row>
          <b-form-group class="mt-1 col-md-12">
            <label for="city_seo_data_title" class="text-capitalize">
              عنوان SEO :</label
            >
            <b-form-input
              lazy
              id="name"
              v-model="residenceInput.seo_data_title"
              type="text"
            />
          </b-form-group>

          <b-form-group class="mt-1 col-md-12">
            <label for="seo_data_keywords" class="text-capitalize">
              کلمات کلیدی (با "،" جدا شود) :
            </label>
            <b-form-textarea
              lazy
              rows="1"
              id="seo_data_keywords"
              v-model="residenceInput.seo_data_keywords"
              type="text"
            />
          </b-form-group>
          <b-form-group class="mt-1 col-md-12">
            <label for="seo_data_description" class="text-capitalize"
              >توضیجات SEO:</label
            >
            <b-form-textarea
              lazy
              rows="4"
              id="seo_data_description"
              v-model="residenceInput.seo_data_description"
              type="text"
            />
          </b-form-group>
          <b-form-group class="mt-1 col-md-12">
            <label for="seo_data_schema" class="text-capitalize"
              >schema SEO:</label
            >
            <b-form-textarea
              lazy
              rows="4"
              id="seo_data_schema"
              v-model="residenceInput.seo_data_schema"
              type="text"
            />
          </b-form-group>
        </b-row>
      </tab-content>
      <!-- <button >ثبت</button> -->
      <b-button slot="finish" variant="primary">
        <div v-if="submitLoading" class="px-1">
          <b-spinner small style="width: 1.3rem; height: 1.3rem" />
          <span class="sr-only">Loading...</span>
        </div>
        <span v-else class="text-capitalize">
          {{ $t("submit") }}
        </span>
      </b-button>
      <b-progress :value="imageUploadPercentage" class="mb-3"></b-progress>
    </form-wizard>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTimepicker,
  BFormTextarea,
  BFormSpinbutton,
  BFormCheckbox,
  BFormRadio,
  BBadge,
  BButton,
  BSpinner,
  BProgress,
  BFormRadioGroup,
  BIconTrash,
  BFormSelect,
} from "bootstrap-vue";
import { codeIconInfo } from "@/components/code";
import "vue-select/dist/vue-select.css";
import { VueEditor } from "vue2-editor";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

import { Icon } from "leaflet";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { forEach } from "postcss-rtl/lib/affected-props";
import QuillEditor from "vue-quill-editor/src/editor.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    QuillEditor,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTimepicker,
    VueEditor,
    BFormTextarea,
    BFormSpinbutton,
    BFormCheckbox,
    BFormRadio,
    BBadge,
    VueUploadMultipleImage,
    BButton,
    BSpinner,
    BProgress,
    BFormRadioGroup,
    BIconTrash,
    BFormSelect,
  },
  props: ["provinces"],
  data() {
    return {
      Selected:'A',


      addRules: false,
      addRegulations: false,
      addPackages: false,
      helperRegulation: [],
      helperPackage: [],
      methodss: [
        { value: "daily", text: "روزانه" },
        { value: "oneTime", text: "یکبار" },
      ],
      dates: null,
      now: new Date(),
      editMode: false,
      residenceIndex: null,
      imageUploadPercentage: 0,
      images: [],
      imagesHelper: [],
      specialServices: 0,
      bedrooms: 0,
      residence_area: [
        "ساحلی",
        "بیابانی",
        "جنگلی",
        "شهری",
        "حومه شهر",
        "روستایی",
        "کوهستانی",
      ],
      residence_time:[
        "نامحدود",
        "12 ظهر",
        "1 ظهر",
        "2 ظهر",
        "3 عصر",
        "4 عصر",
        "5 عصر",
        "6 عصر",
        "7 شب",
        "8 شب",
        "9 شب",
        "10 شب",
        "11 شب",
        "12 شب",
        "1 شب",
        "2 شب",
        "3 شب",
        "4 صبح",
        "5 صبح",
        "6 صبح",
        "7 صبح",
        "8 صبح",
        "9 صبح",
        "10 صبح",
        "11 صبح",
      ],

      rental_method: ["دربست", "اتاق خصوصی", "نیمه دربست", "اتاق مشترک"],
      owners: [],
      clerks: [],
      units: [],
      autoimmunePackagesItemArray: [],
      regulationsItemArray: [],
      rulesItemArray: [],
      packagesItemArray: [],
      categoriesItemArray: [],
      health_itemsItemArray: [],
      categories: [],
      properties: [],
      health_items: [],
      packages: [],
      requlations: [],
      rules: [],
      autoimmunePackages: [],
      showMap: true,
      lat: 29.591768,
      lng: 52.583698,
      zoom: 17,
      center: latLng(29.591768, 52.583698),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 13,
      currentCenter: latLng(29.591768, 52.583698),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showGettingCityLoading: false,
      cities: [],
      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      areas: [
        { id: "1", name: "ساحلی" },
        { id: "2", name: "جتگلی" },
      ],
      areas: [
        { id: "1", name: "دربست" },
        { id: "2", name: "درب مشترک" },
      ],
      locale: "Tehran",
      labels: {
        Tehran: {
          labelHours: "ساعت",
          labelMinutes: "دقیقه",
          labelSeconds: "ثایه",
          labelAmpm: "صبح ظهر",
          labelAm: "ص",
          labelPm: "ظ",
          labelIncrement: "زيادة",
          labelDecrement: "إنقاص",
          labelSelected: "زمان انتخاب شده",
          labelNoTimeSelected: "زمان انتخاب نشده است",
          labelCloseButton: "بستن",
        },
      },
      residenceInput: {
        id: "",
        name: "",
        slug: "",
        host_id: "",
        clerk_id: "",
        unit_id: "",
        category_id: "",
        commission_price: "",
        similar_unit: "",
        size_foundation: "",
        size_land: "",
        floor: "",
        bathroom: "",
        show_order_number: "",
        delivery_time: "",
        delivery_time_to: "",
        discharge_time: "",
        discharge_time_to: "",
        residence_area: "",
        rental_method: "",
        description: "",
        score: "",
        rooms: { 0: { single: 0, double: 0, kaf_khab: 0, description: "" } },
        rules: [],
        properties: [],



        // regulations:{},
        state_id: "",
        city_id: "",
        autoimmune_packages: { 0: "null" },
        health_items: { 0: "null" },
        packages: { 0: "null" },

        prices: {
          sumOfCapacity: "",
          priceType: "pricePerNight",
          normal_dayes: "",
          weekend_days: "",
          peack_days: "",
          extra_guest: "",
          pricePerBaby: {
            name: "هزینه به ازای هر نوزاد",
            hasPrice: false,
            price: "",
          },
          pricePerAnimal: {
            name: "هزینه حیوانات",
            hasPrice: false,
            price: "",
          },
          minNight: 1,
          standardCapacity: 1,
          maxCapacity: 0,
          extraBed: false,
        },
        images: [],
        special_services: { 0: null },
        lat: "",
        lng: "",
      //  status: "",
       // close_booking: "",
        seo_data_title: "",
        seo_data_keywords: "",
        seo_data_description: "",
        seo_data_schema: "",
        youtube_embed: "",
        aparat_embed: "",
        show_index_page: 0,
        status: "active",
        close_booking: "active",

      },
      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      codeIconInfo,

      submitLoading: false,
    };
  },
  mounted() {
    const endofMonth = this.$moment(this.date).endOf("month");
    const toDate = this.$moment(endofMonth).add(6, "months").endOf("month");
    const endofMonth1 = this.$moment(this.date);
    const toDate1 = this.$moment(endofMonth).endOf("month");
    console.log(endofMonth1.format("YYYY/MM/DD"), toDate1.format("YYYY/MM/DD"));
    this.getOwners();
    this.getClerks();
    this.getUnits();
    // this.getResidencesPrerequisite(
    //   "autoimmunePackagesItemArray",
    //   "autoimmune_packages",
    //   "autoimmune_packages"
    // );
    // this.getResidencesPrerequisite(
    //   "regulationsItemArray",
    //   "regulations",
    //   "regulations"
    // );
    // this.getResidencesPrerequisite("rulesItemArray", "rules", "rules");
    // this.getResidencesPrerequisite("packagesItemArray", "packages", "packages");
    // this.getResidencesPrerequisite(
    //   "categoriesItemArray",
    //   "categories",
    //   "categories"
    // );
    // this.getResidencesPrerequisite(
    //   "health_itemsItemArray",
    //   "health_items",
    //   "health_items"
    // );
    this.$nextTick(() => {
        this.$refs.mymap.mapObject._onResize();
      });
  },
  watch: {
    specialServices(newVal, oldVal) {
      if (newVal == oldVal) {
        this.residenceInput.special_services = {
          ...this.residenceInput.special_services,
        };
      }
      if (newVal == 0) {
        this.residenceInput.special_services = { 0: null };
      } else if (newVal > oldVal && newVal !== 0) {
        if (this.residenceInput.special_services[newVal]) {
          this.residenceInput.special_services = {
            ...this.residenceInput.special_services,
          };
        } else {
          this.residenceInput.special_services[newVal] = {
            name: "",
            price: "",
            method: "",
            perPerson: false,
          };
        }
      } else if (newVal < oldVal && newVal !== 0) {
        delete this.residenceInput.special_services[oldVal];
      } else if (newVal == oldVal) {
      }
      console.log("special_services:", this.residenceInput.special_services);
    },
    bedrooms(newVal, oldVal) {
      if (newVal == oldVal) {
        this.residenceInput.rooms = { ...this.residenceInput.rooms };
      }
      if (newVal == 0) {
        this.residenceInput.rooms = {
          0: {
            roomNumber: 0,
            single: 0,
            double: 0,
            kaf_khab: 0,
            description: "",
          },
        };
      } else if (newVal > oldVal && newVal !== 0) {
        if (this.residenceInput.rooms[newVal]) {
          this.residenceInput.rooms = { ...this.residenceInput.rooms };
        } else {
          if (newVal == 1) {
            var x = this.residenceInput.rooms["0"];
            this.residenceInput.rooms = {};
            this.residenceInput.rooms["1"] = {
              roomNumber: 1,
              single: x.single,
              double: x.double,
              kaf_khab: x.kaf_khab,
              description: x.description,
            };
          } else {
            this.residenceInput.rooms[newVal] = {
              roomNumber: newVal,
              single: 0,
              double: 0,
              kaf_khab: 0,
              description: "",
            };
          }
        }
      } else if (newVal < oldVal && newVal !== 0) {
        if (newVal == 1) {
          var x = this.residenceInput.rooms["1"];
          this.residenceInput.rooms = {};
          this.residenceInput.rooms["1"] = x;
        } else {
          delete this.residenceInput.rooms[newVal];
        }
      } else if (newVal == oldVal) {
      }
      console.log("rooms:", this.residenceInput.rooms);
    },
  },
  methods: {
    formatnumber(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return result;
    },
    beforeTab1Switch() {
      if (this.submitLoading) return false;
      let errorMessage = null;
      if (!errorMessage && !this.residenceInput.name) {
        errorMessage = "لطفا نام اقامتگاه را وارد کنید";
      }
      if (!errorMessage && !this.residenceInput.slug) {
        errorMessage = "لطفا اسلاگ اقامتگاه را وارد کنید";
      }
      if (!errorMessage && !this.residenceInput.host_id) {
        errorMessage = "لطفا میزبان را انتخاب کنید";
      }
      if (!errorMessage && !this.residenceInput.clerk_id) {
        errorMessage = "لطفا سرپرست / مدیر ساختمان را انتخاب کنید";
      }
      if (!errorMessage && !this.residenceInput.category_id) {
        errorMessage = "لطفا دسته بندی را انتخاب کنید";
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return false;
      }
      return true;
    },
    searchLatLng() {
      this.center = latLng(this.residenceInput.lat, this.residenceInput.lng);
    },
    beforeTab5Switch() {
      if (this.submitLoading) return false;
      let errorMessage = null;
      if (!errorMessage && !this.residenceInput.prices.normal_dayes) {
        errorMessage = "لطفا قیمت روزهای عادی را وارد کنید";
      }

      if (!errorMessage && !this.residenceInput.prices.weekend_days) {
        errorMessage = "لطفا قیمت آخر هفته را وارد کنید";
      }
      if (!errorMessage && !this.residenceInput.prices.peack_days) {
        errorMessage = "لطفا قمیت روزهای پیک را انتخاب کنید";
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return false;
      }
      this.sliderShown();
      return true;
    },
    beforeTab2Switch() {
      // if (this.submitLoading) return false;
      // let errorMessage = null;
      // if (!errorMessage && !this.residenceInput.state_id) {
      //   errorMessage = "لطفا استان اقامتگاه را وارد کنید";
      // }
      // if (!errorMessage && !this.residenceInput.city_id) {
      //   errorMessage = "لطفا شهر اقامتگاه را وارد کنید";
      // }
      // if (!errorMessage && !this.residenceInput.address) {
      //   errorMessage = "لطفا آدرس دقیق اقامتگاه را وارد کنید";
      // }
      // if (
      //   !errorMessage &&
      //   !this.residenceInput.lat &&
      //   !this.residenceInput.lng
      // ) {
      //   errorMessage = "لطفا لوکیشن را روی نقشه انتخاب کنید";
      // }

      // if (errorMessage) {
      //   this.$bvToast.toast(errorMessage, {
      //     title: this.$t("Error"),
      //     variant: "danger",
      //     solid: true,
      //   });
      //   return false;
      // }
      console.log("lat,lng:", this.residenceInput.lat, this.residenceInput.lng);
      return true;
    },
    beforeTab3Switch() {
      var packages = [];
      this.packagesItemArray.forEach((element) => {
        if (element.disable == true) {
          packages.push({
            residence_packages_id: element.id,
            disable: 1,
            ownerDescription: element.ownerDescription,
          });
        }
      });

      console.log("regulations", packages);
      if (this.submitLoading) return false;
      let errorMessage = null;
      if (!errorMessage && !this.residenceInput.rules) {
        errorMessage = "لطفا قانون لغو رزرو اقامتگاه را وارد کنید";
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return false;
      }
      return true;
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log("data", formData, index, fileList);
      // console.log('formData.keys()', formData.values())
      for (const value of formData.values()) {
        this.imagesHelper.push(value);
        console.log(this.imagesHelper);
      }
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();

        this.imagesHelper.splice(index, 1);
        console.log(this.imagesHelper);
      } else {
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
      for (const value of formData.values()) {
        this.imagesHelper[index] = value;
        console.log(this.imagesHelper);
      }
    },
    markIsPrimary(index, fileList) {
      console.log("index", index);

      const fromIndex = index;
      const toIndex = 0;

      const element = this.imagesHelper.splice(fromIndex, 1)[0];

      this.imagesHelper.splice(toIndex, 0, element);
      console.log("this.imagesHelper", this.imagesHelper);
    },
    onComplete() {
      alert("helowww");
    },
    changeBedrooms($event) {},
    async getOwners() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        //  "filter[name]": this.selectedFilter.includes("name")
        //     ? this.searchInput
        //     : "",
        //   "filter[email]": this.selectedFilter.includes("email")
        //     ? this.searchInput
        //     : "",
        //   "filter[mobile]": this.selectedFilter.includes("mobile")
        //     ? this.searchInput
        //     : "",
       per_page: 5000,
        //   page: this.page,
      };

      const requestData = {
        method: "get",
        url: "/admin/users/host",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.owners = data.data;
          this.paginationData = data.meta;
          this.searchBasesubmitLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.searchBasesubmitLoading = false;
        });
    },
    async getClerks() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        //  "filter[name]": this.selectedFilter.includes("name")
        //     ? this.searchInput
        //     : "",
        //   "filter[email]": this.selectedFilter.includes("email")
        //     ? this.searchInput
        //     : "",
        //   "filter[mobile]": this.selectedFilter.includes("mobile")
        //     ? this.searchInput
        //     : "",
        per_page: 5000,
        //   page: this.page,
      };

      const requestData = {
        method: "get",
        url: "/admin/clerks",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.clerks = data.data;
            this.paginationData = data.meta;
            this.searchBasesubmitLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.searchBasesubmitLoading = false;
          });
    },

    async getUnits() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        //  "filter[name]": this.selectedFilter.includes("name")
        //     ? this.searchInput
        //     : "",
        //   "filter[email]": this.selectedFilter.includes("email")
        //     ? this.searchInput
        //     : "",
        //   "filter[mobile]": this.selectedFilter.includes("mobile")
        //     ? this.searchInput
        //     : "",
        per_page: 5000,
        //   page: this.page,
      };

      const requestData = {
        method: "get",
        url: "/admin/units",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.units = data.data;
            this.paginationData = data.meta;
            this.searchBasesubmitLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.searchBasesubmitLoading = false;
          });
    },
    changeee($event, index) {
      console.log("value", this.packages, $event, index);
      this.$set(this.packages, index, $event.target._value);
    },
    async getResidencesPrerequisite(stateNameHelper, stateName, url) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false, // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `/admin/residences/${url}`,
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      await this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this[stateName] = data.data;
          this[stateName].forEach((element) => {
            element["disable"] = false;
            element["ownerDescription"] = "";
          });
          //           this.packagesItemArray= [
          //   {
          // name: "ACC",
          // ownerDescription: 10,
          // disable: true,
          //   }
          // ],
          this[stateName].forEach((element, index) => {
            this[stateNameHelper].push({
              name: "ACC",
              ownerDescription: 10,
              disable: true,
            });
            Object.keys(element).forEach((element2, index2) => {
              this[stateNameHelper][index][element2] = element[element2];
            });

            // this[stateNameHelper][index].concat(this[stateName][index]);
            this[stateNameHelper][index]["name"] = element.name;
            this[stateNameHelper][index]["ownerDescription"] =
              element.ownerDescription;
            this[stateNameHelper][index]["disable"] = element.disable;
          });
          this.$store.commit(
            `app/UPDATE_RESIDENCE_DATA_${stateName}`,
            this[stateNameHelper]
          );
          console.log(stateNameHelper, this[stateNameHelper]);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addNewfield($event, index) {
      console.log("residenceInput.packages:", $event, index);
      if (!$event) {
        // this.$set(this.this.residenceInput.packages[index], ownerDescription, "");
      } else {
        this.residenceInput.packages[index]["ownerDescription"] = $event;
        //  this.$set(this.residenceInput.packages[index], 'ownerDescription', $events);
        console.log("residenceInput.packages:", this.residenceInput.packages);
      }
    },
    sliderShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.mymap.mapObject._onResize();
      }, 100);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      console.log("this.$refs.mymap:",this.$refs.mymap)
      this.$refs.mymap.mapObject._onResize();
    },
    centerUpdate(center) {
      this.currentCenter = center;

      console.log("center", center);
      this.residenceInput.lat = center.lat;
      this.residenceInput.lng = center.lng;
      // this.center = latLng(this.residenceInput.lat, this.residenceInput.lng);
    },
    onResize() {
      console.log("this.$refs.mymap:",this.$refs.mymap)
      this.$refs.mymap.mapObject._onResize();
    },
    getSelectedProvincesCity(provinceId) {
      this.showGettingCityLoading = true;
      this.getCityByProvinceId(provinceId);
    },
    async getCityByProvinceId(provinceId) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `/admin/cities/${provinceId}`,
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.cities = data.data;
          this.paginationData = data.meta;
          this.showGettingCityLoading = false;
          if (!this.editMode) {
            this.residenceInput.city_id = this.cities[0]["id"];
          }
        })
        .catch((err) => {
          console.log(err);
          this.showGettingCityLoading = false;
        });
    },
    onContext(ctx) {
      this.context = ctx;
    },
    deFormatePrices(prices, type) {
      if (type == "prices") {
        var formatedPrices = prices;
        if (prices.normal_dayes)
          formatedPrices.normal_dayes = this.deFormatnumber(
            prices.normal_dayes
          );
        if (prices.weekend_days)
          formatedPrices.weekend_days = this.deFormatnumber(
            prices.weekend_days
          );
        if (prices.peack_days)
          formatedPrices.peack_days = this.deFormatnumber(prices.peack_days);
        if (prices.extra_guest)
          formatedPrices.extra_guest = this.deFormatnumber(prices.extra_guest);
        if (prices.pricePerBaby.price)
          formatedPrices.pricePerBaby.price = this.deFormatnumber(
            prices.pricePerBaby.price
          );
        if (prices.pricePerAnimal.price)
          formatedPrices.pricePerAnimal.price = this.deFormatnumber(
            prices.pricePerAnimal.price
          );
        return formatedPrices;
      } else {
        var formatedSpecial_services = prices;
        if (prices && prices[0] !== null)
          Object.keys(prices).forEach(function(element) {
            if (prices[element] && typeof prices[element] === 'object' && prices[element].price !== undefined) {
              formatedSpecial_services[element].price = _this6.deFormatnumber(prices[element].price);
            } else {

            }
          });

        return formatedSpecial_services;
      }
    },
/*    deFormatnumber(value) {
      console.log("deforamt:", value);
      var a = value;
      a = a.replace(/\,/g, ""); // 1125, but a string, so convert it to number
      a = parseInt(a, 10);
      return a;
    },*/
    deFormatnumber(value) {
      console.log("deforamt:", value);
      if (value === null || value === undefined) {
        return 0; // یا هر مقدار پیشفرضی که مد نظرتان است
      }
      var a = value.replace(/\,/g, ""); // حذف کاما
      a = parseInt(a, 10);
      return a;
    },

    createTestCalender(id) {
      console.log(this.saveCourierCalendars(id));
    },
    saveCourierCalendars(id) {
      // console.log({ data: this.getDatesFromStartTo6Month() });
      const requestData = {
        method: "post",
        url: `/admin/residence/calendars/${id}`,
        data: { calendars: this.getDatesFromStartTo6Month(id) },
      };

      this.$http(requestData)
        .then(async (response) => {
          // console.log(response)
          let message = "تقویم اقامتگاه با موفقیت ایجاد شد";
          if (this.editMode) message = "اقامتگاه با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          let message = "خطا در ایجاد تفویم اقامتگاه";
          if (this.editMode) message = "خطا در ویرایش تقویم اقامتگاه";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Form Submitted",
      //     icon: "EditIcon",
      //     variant: "success",
      //   },
      // });
    },
    monthsBetweenDates(startDate, endDate, id) {
      const now = startDate,
        dates = [];
      console.log("startDate:", startDate, "endDate:", endDate);
      while (now.isSameOrBefore(endDate)) {
        console.log("now", now, now.isoWeekday());
        if (now.isoWeekday() === 3) {
          // console.log(now.format("dddd"));
          // console.log('now',now,now.weekday());
          dates.push({
            residence_id: id,
            date: now.locale("en").format("YYYY-MM-DD"),
            price: this.residenceInput.prices.weekend_days,
            instant: null,
            discount: null,
            minNight: null,
            isWeakened: 1,
            books_count: 0,
            disable_count: 0,
            is_custom_price: 0,
            extra_guest_price: null,
          });
        } else if (now.isoWeekday() === 4) {
          // console.log(now.format("dddd"));
          // console.log('now',now,now.weekday());
          dates.push({
            residence_id: id,
            date: now.locale("en").format("YYYY-MM-DD"),
            price: this.residenceInput.prices.weekend_days,
            instant: null,
            discount: null,
            minNight: null,
            isWeakened: 1,
            books_count: 0,
            disable_count: 0,
            is_custom_price: 0,
            extra_guest_price: null,
          });
        } else {
          dates.push({
            residence_id: id,
            date: now.locale("en").format("YYYY-MM-DD"),
            price: this.residenceInput.prices.normal_dayes,
            instant: null,
            discount: null,
            minNight: null,
            isWeakened: 0,
            books_count: 0,
            disable_count: 0,
            is_custom_price: 0,
            extra_guest_price: null,
          });
        }

        now.add(1, "days");
      }
      return dates;
    },
    getDatesFromStartTo6Month(id) {
      // console.log(this.$moment)
      const endofMonth1 = this.$moment(this.date);
      const toDate1 = this.$moment(endofMonth).endOf("month");
      console.log(endofMonth1, toDate1);
      // console.log("monthsBetweenDates",this.monthsBetweenDates(endofMonth1,toDate1))
      // console.log("date",this.$options.filters.moment(this.date,"jD jMMMM,jYYYY HH:mm").getDay())
      // console.log(this.$moment(this.date).endOf('month').format('YYYY/MM/D'))
      const endofMonth = this.$moment(this.date).endOf("month");
      const toDate = this.$moment(endofMonth).add(6, "months").endOf("month");

      // console.log("toDate",toDate.format('YYYY/MM/D'))
      // console.log("monthsBetweenDates",this.monthsBetweenDates(endofMonth,toDate))
      var x = this.monthsBetweenDates(endofMonth1, toDate1, id);

      // x.splice(-1);
      var y = this.monthsBetweenDates(endofMonth, toDate, id);
      y.shift();
      // this.date.moment("jD jMMMM,jYYYY HH:mm").getDay()
      this.Dates = [...x, ...y];

      console.log(this.Dates);
      return this.Dates;
    },
    async formSubmitted() {
      var vm=this
      this.residenceInput.autoimmune_packages =
        this.autoimmunePackagesItemArray;

        this.residenceInput.health_items = this.health_itemsItemArray;


        this.residenceInput.autoimmune_packages.forEach((ap,apIndex) => {
          if (vm.editMode) {
          if(ap.ownerDescription=="undefined"){
            ap.ownerDescription=null
          }
          }
        });

        this.residenceInput.health_items.forEach((hi,apIndex) => {
          if (this.editMode) {
          if(hi.ownerDescription=="undefined"){
            hi.ownerDescription=null
          }
          }
        });
      //  this.autoimmunePackagesItemArray.filter((item) => item.disable);
      // this.residenceInput.regulations = this.regulationsItemArray;

      var regulations = [];
      this.regulationsItemArray.forEach((element) => {
        if (element.disable == true) {
          if (this.editMode) {
            regulations.push({
              residence_regulation_id: element.residence_regulation
                ? element.residence_regulation.id
                : element.id,
              disable: 1,
              ownerDescription: element.ownerDescription ? element.ownerDescription: null
            });
          } else {
            regulations.push({
              residence_regulation_id: element.id,
              disable: 1,
              ownerDescription: element.ownerDescription ? element.ownerDescription: null
            });
          }
        }
      });

      var rules = [];
      this.rules.forEach((element) => {
        if (this.residenceInput.rules[0].id == element.id) {
          rules.push({
            residence_rule_id: element.id,
            disable: 1,
            ownerDescription: "",
          });
        }
      });

      var packages = [];
      console.log("this.packagesItemArray", this.packagesItemArray);
      this.packagesItemArray.forEach((element) => {
        if (element.disable == true) {
          if (this.editMode) {
            packages.push({
              residence_package_id: element.residence_package
                ? element.residence_package.id
                : element.id,
              disable: 1,
              ownerDescription: element.ownerDescription
                ? element.ownerDescription
                : null,
            });
          } else {
            packages.push({
              residence_package_id: element.id,
              disable: 1,
              ownerDescription: element.ownerDescription
                ? element.ownerDescription
                : null,
            });
          }
        }
      });
      if (this.editMode) {
        var packagesToDelete = [];
        var residenceId = "residence_id";
        this.helperPackage.forEach((element) => {
          packagesToDelete.push({
            id: element.id,
          });

          residenceId = element.residence_id;
        });

        var regulationsToDelete = [];
        var residenceId = "residence_id";
        this.helperRegulation.forEach((element) => {
          regulationsToDelete.push({
            id: element.id,
          });

          residenceId = element.residence_id;
        });

        var rulesToDelete = [];
        var residenceId = "residence_id";
        this.helperRules.forEach((element) => {
          rulesToDelete.push({
            id: element.id,
          });

          residenceId = element.residence_id;
        });
        console.log("packagesToDelete", this.helperPackage, packagesToDelete);
      }
      // this.residenceInput.rules= this.rulesItemArray.filter((item) => item.disable)

      this.residenceInput.rooms["sumOfRooms"] = this.bedrooms;
      this.residenceInput.rooms["sumOfAllBed"] = 0;
      var sum = 0;
      Object.keys(this.residenceInput.rooms).forEach((key) => {
        if (key !== "sumOfRooms" && key !== "sumOfAllBed") {
          sum =
            sum +
            Number(this.residenceInput.rooms[key].single) +
            Number(this.residenceInput.rooms[key].double);
        }
      });
      this.residenceInput.rooms["sumOfAllBed"] = sum;
      Object.keys(this.residenceInput.rooms);
      this.submitLoading = true;
      console.log("this.residenceInput", this.residenceInput);
      this.deFormatePrices(this.residenceInput.prices, "prices");
      this.deFormatePrices(
        this.residenceInput.special_services,
        "special_services"
      );
      this.residenceInput.prices.sumOfCapacity =
        this.residenceInput.prices.normal_dayes +
        this.residenceInput.prices.weekend_days;
      console.log("kkkkk", this.residenceInput);
      const requestData = {
        method: this.editMode ? "put" : "post",
        url: this.editMode
          ? `/admin/residences/${this.residenceInput.id}`
          : `/admin/residences`,
        data: this.residenceInput,
      };
      if (this.editMode) {
        if (rules.length > 0) this.submitRules(rules, this.residenceInput.id);
        if (regulations.length > 0)
          this.submitRegulations(regulations, this.residenceInput.id);
        if (packages.length > 0)
          this.submitPackages(packages, this.residenceInput.id);
      }

      await this.$http(requestData)
        .then(async (response) => {
          // console.log(response)
          if (this.imagesHelper.length > 0)
            await this.uploadImage(response.data.data.id);
          if (!this.editMode) {
            this.saveCourierCalendars(response.data.data.id);
          }
          if (!this.editMode) {
            if (rules.length > 0)
              await this.submitRules(rules, response.data.data.id);
            if (regulations.length > 0)
              await this.submitRegulations(regulations, response.data.data.id);
            if (packages.length > 0)
              await this.submitPackages(packages, response.data.data.id);
          }

          let message = "اقامتگاه با موفقیت اضافه شد";
          if (this.editMode) message = "اقامتگاه با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
	      //location.reload();
	  
          if (!this.editMode && this.users && this.users.length > 0) {
            this.$refs.adminsPaginationComponent.clear();
            this.$refs.adminsSearchComponent.clear();
            this.$refs.adminsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          console.log("imageUploader", this.$refs.imageUploader);
          // this.$refs.imageUploader.clear();
          this.imagesHelper = [];
          this.images = [];
          this.imageUploadPercentage = 0;
          if (this.imagesHelper.length == 0) {
            this.getResidences();
          }
        })
        .catch((err) => {
          let message = "خطا در ایجاد اقامتگاه";
          if (this.editMode) message = "خطا در ویرایش اقامتگاه";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
          this.$refs.imageUploader.clear();
          this.imagesHelper = [];
          this.images = [];
          this.imageUploadPercentage = 0;
        });


      //location.reload();
    },
    getResidences() {
      this.$emit("getResidences");
    },
    uploadImage(id) {
      let formData = new FormData();
      this.imagesHelper.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });
      const config = {
        //Choose upload
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          this.imageUploadPercentage = Math.floor((loaded * 100) / total);

          if (this.imageUploadPercentage < 100) {
            console.log(this.imageUploadPercentage);
          }
        },
      };
      const requestData = {
        method: "post",
        url: `/admin/residences/upload_media/${id}`,
        data: formData,
        config: config,
      };

      this.$http
        .post(requestData.url, formData, config)
        .then((response) => {
          console.log("trueeeeeeee");
          this.getResidences();
          return true;
        })
        .catch((err) => {
          console.log("falseeee");
          return false;
        });
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Form Submitted",
      //     icon: "EditIcon",
      //     variant: "success",
      //   },
      // });
    },
    deleteImage(image, id) {
      this.$bvModal
        .msgBoxConfirm(`آیا از حذف عکس مطمئن هستید ؟  : ${image.id}.`, {
          title: this.$t("Please Confirm"),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (confirmed) => {
          if (confirmed) {
            let residenceIndex = 0;
            this.residenceInput.images = await Object.values(
              this.residenceInput.images
            ).filter((residenceItem, index) => {
              if (residenceItem.id == image.id) {
                residenceIndex = index;
              }
              return residenceItem.id !== image.id;
            });

            const requestData = {
              method: "delete",
              url: `admin/residences/delete_media/${id}/${image.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(`عکس: ${image.id}  با موفقیت حذف شد.`, {
                  title: this.$t("Done"),
                  variant: "success",
                  solid: true,
                });
                this.$emit("deleteImage", image);
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.residenceInput.images.splice(residenceIndex, 0, image);
              });
          }
        });
    },
    async submitRules(rules, id) {
      this.addRules = true;
      console.log("rules,", rules);
      const requestData = {
        method: "post",
        url: `/admin/residences/rules/save/${id}`,
        data: { rules: rules },
      };
      await this.$http(requestData)
        .then((response) => {
          // console.log(response)
          let message = "قوانین لغو با موفقیت اضافه شد";
          if (this.editMode) message = "قوانین لغو با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          this.addRules = false;
          // if (this.imagesHelper.length == 0) {
          //   this.getResidences();
          // }
        })
        .catch((err) => {
          this.addRules = false;
          let message = "خطا در ایجاد اقامتگاه";
          if (this.editMode) message = "خطا در اضافه کردن قوانین لغو اقامتگاه";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
        });
    },
    async submitRegulations(regulations, id) {
      this.addRegulations = true;
      console.log("regulations,", regulations);
      const requestData = {
        method: "post",
        url: `/admin/residences/regulations/save/${id}`,
        data: { regulations: regulations },
      };
      await this.$http(requestData)

        .then((response) => {
          // console.log(response)
          let message = "قوانین اقامتگاه با موفقیت اضافه شد";
          if (this.editMode) message = "قوانین اقامتگاه با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          this.addRegulations = false;
          // if (this.imagesHelper.length == 0) {
          //   this.getResidences();
          // }
        })
        .catch((err) => {
          let message = "خطا در ایجاد اقامتگاه";
          if (this.editMode) message = "خطا در ویرایش قوانین اقامتگاه";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          this.addRegulations = false;
          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
        });
    },
    async submitPackages(packages, id) {
      console.log("packages,", packages);
      this.addPackages = true;
      const requestData = {
        method: "post",
        url: `/admin/residences/packages/save/${id}`,
        data: { packages: packages },
      };
      await this.$http(requestData)
        .then((response) => {
          // console.log(response)
          let message = "امکانات اقامتگاه با موفقیت اضافه شد";
          if (this.editMode) message = "امکانات اقامتگاه با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          this.addPackages = false;
          // if (this.imagesHelper.length == 0) {
          //   this.getResidences();
          // }
        })
        .catch((err) => {
          this.addPackages = false;
          let message = "خطا در ایجاد اقامتگاه";
          if (this.editMode) message = "خطا در ویرایش اقامتگاه";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
        });
    },
    getSelectedCityLocaion(value){
      console.log('city:',value)
     var index= this.cities.findIndex(item=>item.id==value)

     this.center = latLng(this.cities[index].lat, this.cities[index].lng);

    },
    doSomethingOnReady(){
      this.$nextTick(() => {
        setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.mymap.mapObject._onResize();
        this.$refs.mymap.mapObject.setZoom(11)
      }, 5000);
       
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
// [dir=rtl] #wizard {
//     direction: ltr !important;
//     text-align: left !important;
// }
.dropup .dropdown-menu {
}


[dir="rtl"] .b-form-timepicker .dropdown-menu {
  right: 32px !important;
}
.image-container {
  width: 100% !important;
  height: 220px !important;
}
.preview-image {
  height: 165px !important;
}
.show-img {
  max-height: 100% !important;
}
</style>
<style  lang="scss">
/* required styles */

[dir=rtl] .leaflet-pane,
[dir=rtl] .leaflet-tile,
[dir=rtl] .leaflet-marker-icon,
[dir=rtl] .leaflet-marker-shadow,
[dir=rtl] .leaflet-tile-container,
[dir=rtl] .leaflet-pane > svg,
[dir=rtl] .leaflet-pane > canvas,
[dir=rtl] .leaflet-zoom-box,
[dir=rtl] .leaflet-image-layer,
[dir=rtl] .leaflet-layer {
  position: absolute!important;
  left: 0!important;
  top: 0!important;
  direction: ltr !important;
}



[dir="rtl"] .leaflet-container {
  overflow: hidden;
  direction: ltr !important;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}
.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  /* behavior: url(#default#VML); */
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-top {
  top: 0;
}
.leaflet-right {
  left: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: 0;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}
.leaflet-container a {
  color: #0078a8;
}
.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}
.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a:hover {
  background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  /* background-image: url(images/layers.png); */
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  /* background-image: url(images/layers-2x.png); */
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
/* .leaflet-default-icon-path {
	background-image: url(images/marker-icon.png);
	} */

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}
.leaflet-popup-content p {
  margin: 18px 0;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;

  margin: -10px auto 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;

  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}
</style>
